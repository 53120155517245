import { ValuePropsGetUser } from "../../utils/interface.global";
import { URL_API } from "../constants/api";
import Api from "./api";

export const loginUserByAPI = async (props: ValuePropsGetUser) => {
  const reqUrl = URL_API.LOGIN;

  const res = await Api.post(reqUrl, props);
  //console.log("res", res.data);
  return res;
};

export const refreshTokenByAPI = async (props: any, jwt: string) => {
  const reqUrl = URL_API.REFRESH_TOKEN;

  const res = await Api.post(reqUrl, props);
  //console.log("res", res.data);
  return res;
};

export const resetPasswordByAPI = async (props: any, jwt: string) => {
  const reqUrl = URL_API.CHANGE_PASSWORD;

  const res = await Api.post(reqUrl, props, jwt);
  //console.log("res", res.data);
  return res;
};

export const addBankByAPI = async (props: any, jwt: string) => {
  const reqUrl = URL_API.ADD_BANK;

  const res = await Api.post(reqUrl, props, jwt);
  //console.log("res", res.data);
  return res;
};

export const getListBankByAPI = async (props: any, jwt: string) => {
  const reqUrl = URL_API.LIST_BANK;

  const res = await Api.get(reqUrl, props, jwt);
  //console.log("res", res.data);
  return res;
};

export const updateBankByAPI = async (props: any, jwt: string) => {
  const reqUrl = URL_API.UPDATE_BANK;

  const res = await Api.post(reqUrl, props, jwt);
  //console.log("res", res.data);
  return res;
};

export const getListAPIKeyByAPI = async (props: any, jwt: string) => {
  const reqUrl = URL_API.LIST_API_KEY;

  const res = await Api.post(reqUrl, props, jwt);
  //console.log("res", res.data);
  return res;
};

export const addAPIKeyByAPI = async (props: any, jwt: string) => {
  const reqUrl = URL_API.ADD_API_KEY;

  const res = await Api.post(reqUrl, props, jwt);
  //console.log("res", res.data);
  return res;
};

export const updateAPIKeyByAPI = async (props: any, jwt: string) => {
  const reqUrl = URL_API.UPDATE_API_KEY;

  const res = await Api.post(reqUrl, props, jwt);
  //console.log("res", res.data);
  return res;
};
