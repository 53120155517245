import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, logout } from "../redux/slices/userSlices";
import {
  getListBankByAPI,
  updateBankByAPI,
} from "../components/api/authentication";
import { useNavigate } from "react-router-dom";

interface Bank {
  id: number;
  friendlyName: string | null;
  bankName: string;
  status: string;
  accountNo: string;
  username: string;
  balance: number;
  createdAt: string;
  updatedAt: string;
  userId: number;
  lastLogin: string | null;
  lastSync: string | null;
  isEnabledSync: boolean;
  lastSyncIndex: number;
  telegramChatId: string | null;
  telegramBotKey: string | null;
  isEnabledTelegramNotification: boolean;
}

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const [banks, setBanks] = useState<Bank[]>([]);
  const [loading, setLoading] = useState(true);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
  const [editForm, setEditForm] = useState<Partial<Bank>>({});

  useEffect(() => {
    console.log(dataUser);
    if (dataUser && dataUser.tokens && dataUser.tokens.access.token) {
      fetchBanks();
    }
  }, []);

  const fetchBanks = async () => {
    setLoading(true);
    const result = await getListBankByAPI({}, dataUser.tokens.access.token);
    console.log("result home", result);
    if (result.status == 200) {
      setBanks(result.data);
      setLoading(false);
    } else if (result.status == 401) {
      dispatch(logout());
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleProfile = () => {
    navigate("/app/profile");
  };

  const handleAddBank = () => {
    navigate("/app/add-bank");
  };

  const showDetailModal = (bank: Bank) => {
    setSelectedBank(bank);
    setDetailModalVisible(true);
  };

  const showEditModal = (bank: Bank) => {
    setSelectedBank(bank);
    setEditForm(bank);
    setEditModalVisible(true);
  };

  const handleEdit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const result = await updateBankByAPI(
        {
          bankId: editForm.id,
          isEnabledSync: editForm.isEnabledSync,
          telegramChatId: editForm.telegramChatId,
          telegramBotKey: editForm.telegramBotKey,
          isEnabledTelegramNotification: editForm.isEnabledTelegramNotification,
          friendlyName: editForm.friendlyName,
        },
        dataUser.tokens.access.token
      );
      // Gọi API cập nhật thông tin ngân hàng ở đây
      console.log("result home", result);
      if (result.status == 200) {
        alert("Cập nhật thành công");
        setEditModalVisible(false);
        fetchBanks(); // Tải lại danh sách sau khi cập nhật
      } else if (result.status == 401) {
        dispatch(logout());
      }
    } catch (error) {
      console.error("Error updating bank:", error);
      alert("Cập nhật thất bại");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setEditForm((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Danh sách Ngân hàng</h1>
        <div className="space-x-2">
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleAddBank}
          >
            Add Bank
          </button>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleProfile}
          >
            Profile
          </button>
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleLogout}
          >
            Đăng xuất
          </button>
        </div>
      </div>

      {loading ? (
        <p className="text-center">Đang tải...</p>
      ) : (
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                ID
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Tên
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Tên Ngân Hàng
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Số Tài Khoản
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Trạng Thái
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Số Dư
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Đồng bộ
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Thao Tác
              </th>
            </tr>
          </thead>
          <tbody>
            {banks.map((bank) => (
              <tr key={bank.id}>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  {bank.id}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  {bank.friendlyName ?? "--"}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  {bank.bankName}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  {bank.accountNo}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  {bank.status}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  {bank.balance.toLocaleString()} VND
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  {bank.isEnabledSync ? "RUNNING" : "STOP"}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  <button
                    onClick={() => showDetailModal(bank)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2"
                  >
                    Chi tiết
                  </button>
                  <button
                    onClick={() => showEditModal(bank)}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
                  >
                    Chỉnh sửa
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {detailModalVisible && selectedBank && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
          id="my-modal"
        >
          <div className="relative top-20 mx-auto p-5 border w-[50%] shadow-lg rounded-md bg-white">
            <div className="mt-3">
              <h3 className="text-lg leading-6 font-medium text-gray-900 text-center mb-4">
                Chi tiết Ngân hàng
              </h3>
              <div className="mt-2 px-7 py-3">
                <table className="w-full text-left">
                  <tbody>
                    <tr className="bg-gray-100">
                      <td className="px-4 py-2 font-semibold">Tên Ngân hàng</td>
                      <td className="px-4 py-2">{selectedBank.bankName}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold">Số tài khoản</td>
                      <td className="px-4 py-2">{selectedBank.accountNo}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold">Tên đăng nhập</td>
                      <td className="px-4 py-2">{selectedBank.username}</td>
                    </tr>
                    <tr className="bg-gray-100">
                      <td className="px-4 py-2 font-semibold">Trạng thái</td>
                      <td className="px-4 py-2">{selectedBank.status}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold">Số dư</td>
                      <td className="px-4 py-2">
                        {selectedBank.balance.toLocaleString()} VND
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <td className="px-4 py-2 font-semibold">Ngày tạo</td>
                      <td className="px-4 py-2">
                        {new Date(selectedBank.createdAt).toLocaleString()}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold">
                        Lần đăng nhập cuối
                      </td>
                      <td className="px-4 py-2">
                        {selectedBank.lastLogin
                          ? new Date(selectedBank.lastLogin).toLocaleString()
                          : "Chưa có"}
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <td className="px-4 py-2 font-semibold">
                        Lần đồng bộ cuối
                      </td>
                      <td className="px-4 py-2">
                        {selectedBank.lastSync
                          ? new Date(selectedBank.lastSync).toLocaleString()
                          : "Chưa có"}
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <td className="px-4 py-2 font-semibold">
                        Trạng thái đồng bộ
                      </td>
                      <td className="px-4 py-2">
                        {selectedBank.isEnabledSync ? "RUNNING" : "STOP"}
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <td className="px-4 py-2 font-semibold">
                        telegramChatId
                      </td>
                      <td className="px-4 py-2">
                        {selectedBank.telegramChatId}
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <td className="px-4 py-2 font-semibold">
                        telegramBotKey
                      </td>
                      <td className="px-4 py-2">
                        {selectedBank.telegramBotKey}
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <td className="px-4 py-2 font-semibold">
                        Trạng thái thông báo telegram
                      </td>
                      <td className="px-4 py-2">
                        {selectedBank.isEnabledTelegramNotification
                          ? "ACTIVE"
                          : "UNACTIVE"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="items-center px-4 py-3">
                <button
                  id="ok-btn"
                  className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                  onClick={() => setDetailModalVisible(false)}
                >
                  Đóng
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {editModalVisible && selectedBank && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
          id="my-modal"
        >
          <div className="relative top-20 mx-auto p-5 border w-[50%] shadow-lg rounded-md bg-white">
            <div className="mt-3">
              <h3 className="text-lg leading-6 font-medium text-gray-900 text-center mb-4">
                Chỉnh sửa Ngân hàng
              </h3>
              <form onSubmit={handleEdit} className="mt-2">
                <table className="w-full text-left">
                  <tbody>
                    <tr className="bg-gray-100">
                      <td className="px-4 py-2 font-semibold">Tên</td>
                      <td className="px-4 py-2">
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="friendlyName"
                          type="text"
                          name="friendlyName"
                          value={editForm.friendlyName || ""}
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <td className="px-4 py-2 font-semibold">Tên Ngân hàng</td>
                      <td className="px-4 py-2">{selectedBank.bankName}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold">Số tài khoản</td>
                      <td className="px-4 py-2">{selectedBank.accountNo}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold">Tên đăng nhập</td>
                      <td className="px-4 py-2">{selectedBank.username}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold">
                        Cho phép đồng bộ
                      </td>
                      <td className="px-4 py-2">
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            name="isEnabledSync"
                            checked={editForm.isEnabledSync || false}
                            onChange={handleInputChange}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2 text-gray-700">
                            Cho phép đồng bộ
                          </span>
                        </label>
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <td className="px-4 py-2 font-semibold">
                        Telegram Chat ID
                      </td>
                      <td className="px-4 py-2">
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="telegramChatId"
                          type="text"
                          name="telegramChatId"
                          value={editForm.telegramChatId || ""}
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold">
                        Telegram Bot Key
                      </td>
                      <td className="px-4 py-2">
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="telegramBotKey"
                          type="text"
                          name="telegramBotKey"
                          value={editForm.telegramBotKey || ""}
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <td className="px-4 py-2 font-semibold">
                        Bật thông báo Telegram
                      </td>
                      <td className="px-4 py-2">
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            name="isEnabledTelegramNotification"
                            checked={
                              editForm.isEnabledTelegramNotification || false
                            }
                            onChange={handleInputChange}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2 text-gray-700">
                            Bật thông báo Telegram
                          </span>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="flex items-center justify-between mt-6">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Cập nhật
                  </button>
                  <button
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={() => setEditModalVisible(false)}
                  >
                    Hủy
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
