import React, { useEffect, useState } from "react";
import "./App.css";
import Home from "./pages/Home";
import ProtectedRouter from "./auth/ProtectedRouter";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import DataContext from "./context/DataContext";
import "react-toastify/dist/ReactToastify.css";
import ProtectedLogin from "./auth/ProtectedLogin";
import Login from "./pages/Login";
import Logout from "./pages/logout";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, logout, updateData } from "./redux/slices/userSlices";
import { refreshTokenByAPI } from "./components/api/authentication";
import ProfilePage from "./pages/Profile";
import AddBank from "./pages/AddBank";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      { element: <Navigate to="/Login" />, index: true },
      {
        path: "/Login",
        element: <ProtectedLogin props={<Login />} type={""} key={""} />,
      },
    ],
  },
  {
    path: "/app/*",
    element: <ProtectedRouter />,
    children: [
      { path: "", element: <Home /> },
      { path: "profile", element: <ProfilePage /> },
      { path: "add-bank", element: <AddBank /> },
      { path: "logout", element: <Logout /> },
    ],
  },
]);

function App() {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const dataUser = useSelector(getUserData);

  useEffect(() => {
    const refreshTokenPeriodically = async () => {
      if (dataUser && dataUser.tokens && dataUser.tokens.access) {
        const currentTime = new Date();
        const expiryTime = new Date(dataUser.tokens.access.expires);
        const timeUntilExpiry = expiryTime.getTime() - currentTime.getTime();

        // If token expires in less than 5 minutes, refresh it
        console.log(
          "refreshTokenPeriodically: timeUntilExpiry",
          timeUntilExpiry,
          5 * 60 * 1000
        );
        if (timeUntilExpiry < 5 * 60 * 1000) {
          try {
            const result = await refreshTokenByAPI(
              {
                refreshToken: dataUser.tokens.refresh.token,
              },
              ""
            );
            console.log("refreshTokenPeriodically: result", result);
            if (result.status === 200) {
              dispatch(
                updateData({
                  ...dataUser,
                  tokens: {
                    refresh: result.data.refresh,
                    access: result.data.access,
                  },
                } as any)
              );
            } else {
              dispatch(logout());
            }
          } catch (error) {
            console.error("Failed to refresh token:", error);
            dispatch(logout());
          }
        }
      }
    };

    const refreshInterval = setInterval(refreshTokenPeriodically, 60 * 1000);

    // Initial refresh
    refreshTokenPeriodically();

    return () => clearInterval(refreshInterval);
  }, [dispatch]);

  return (
    <DataContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      <div className="font-samsung w-full h-full min-h-screen bg-gradient-custom">
        <RouterProvider router={router} />
      </div>
    </DataContext.Provider>
  );
}

export default App;
