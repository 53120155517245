import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserData } from "../redux/slices/userSlices";
import { addBankByAPI } from "../components/api/authentication";

const AddBank: React.FC = () => {
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const [formData, setFormData] = useState({
    friendlyName: "",
    accountNo: "",
    username: "",
    password: "",
    bankName: "",
  });

  const bankOptions = [
    { value: "vietcombank", label: "Vietcombank" },
    { value: "mbbank", label: "MBbank" },
    { value: "acbbank", label: "ACBbank" },
    // Add more banks as needed
  ];

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const result = await addBankByAPI(formData, dataUser.tokens.access.token);
      if (result.status === 200) {
        alert("Bank added successfully");
        navigate("/app");
      } else {
        alert("Failed to add bank");
      }
    } catch (error) {
      console.error("Error adding bank:", error);
      alert("An error occurred while adding the bank");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Add New Bank</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1">Friendly Name</label>
          <input
            type="text"
            name="friendlyName"
            value={formData.friendlyName}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1">Account Number</label>
          <input
            type="text"
            name="accountNo"
            value={formData.accountNo}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1">Username</label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1">Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1">Bank Name</label>
          <select
            name="bankName"
            value={formData.bankName}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          >
            <option value="">Select a bank</option>
            {bankOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
          >
            Add Bank
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddBank;
