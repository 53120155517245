export enum URL_API {
  LOGIN = "https://bank-service.martool.online/v1/auth/login",
  REFRESH_TOKEN = "https://bank-service.martool.online/v1/auth/refresh-tokens",
  CHANGE_PASSWORD = "https://bank-service.martool.online/v1/auth/change-password",
  LIST_BANK = "https://bank-service.martool.online/v1/banks",
  ADD_BANK = "https://bank-service.martool.online/v1/banks/add",
  UPDATE_BANK = "https://bank-service.martool.online/v1/banks/update",
  LIST_API_KEY = "https://bank-service.martool.online/v1/users/get-list-api-key",
  ADD_API_KEY = "https://bank-service.martool.online/v1/users/add-api-key",
  UPDATE_API_KEY = "https://bank-service.martool.online/v1/users/update-api-key",
}
