import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserData } from "../redux/slices/userSlices";
import {
  addAPIKeyByAPI,
  getListAPIKeyByAPI,
  resetPasswordByAPI,
  updateAPIKeyByAPI,
} from "../components/api/authentication";
import { useNavigate } from "react-router-dom";

interface APIKey {
  id: string;
  key: string;
  isRevoked: boolean;
  createdAt: string;
}

const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getUserData);
  const [password, setPassword] = useState({ new: "", confirm: "" });
  const [apiKeys, setApiKeys] = useState<APIKey[]>([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [activeSection, setActiveSection] = useState<string | null>(
    "changePassword"
  );

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const fetchApiKeys = async () => {
    try {
      const result = await getListAPIKeyByAPI({}, user.tokens.access.token);
      if (result.status === 200) {
        setApiKeys(result.data);
        setSuccess("API keys fetched successfully");
      }
    } catch (error) {
      setError("Failed to fetch API keys");
    }
  };

  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (password.new !== password.confirm) {
      setError("New passwords do not match");
      return;
    }

    try {
      const result = await resetPasswordByAPI(
        { password: password.new },
        user.tokens.access.token
      );
      setSuccess("Password changed successfully");
      setPassword({ new: "", confirm: "" });
    } catch (error) {
      setError("Failed to change password");
    }
  };

  const handleCreateApiKey = async () => {
    try {
      await addAPIKeyByAPI({}, user.tokens.access.token);
      fetchApiKeys();
    } catch (error) {
      setError("Failed to create API key");
    }
  };

  const handleToggleRevoke = async (keyId: string, currentStatus: boolean) => {
    try {
      await updateAPIKeyByAPI(
        { apiId: keyId, isRevoked: !currentStatus },
        user.tokens.access.token
      );
      fetchApiKeys();
    } catch (error) {
      setError("Failed to update API key status");
    }
  };

  const handleBack = () => {
    navigate("/app");
  };

  const toggleSection = (section: string) => {
    setActiveSection(activeSection === section ? null : section);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Profile</h1>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleBack}
        >
          Back
        </button>
      </div>

      {/* Accordion Sections */}
      <div className="space-y-4">
        {/* Change Password Section */}
        <div className="border rounded-lg">
          <button
            className="w-full text-left p-4 font-semibold bg-gray-100 hover:bg-gray-200 focus:outline-none"
            onClick={() => toggleSection("changePassword")}
          >
            Change Password {activeSection === "changePassword" ? "▲" : "▼"}
          </button>
          {activeSection === "changePassword" && (
            <div className="p-4">
              <form onSubmit={handleChangePassword} className="space-y-4">
                <div>
                  <label className="block mb-1">New Password</label>
                  <input
                    type="password"
                    value={password.new}
                    onChange={(e) =>
                      setPassword({ ...password, new: e.target.value })
                    }
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div>
                  <label className="block mb-1">Confirm New Password</label>
                  <input
                    type="password"
                    value={password.confirm}
                    onChange={(e) =>
                      setPassword({ ...password, confirm: e.target.value })
                    }
                    className="w-full p-2 border rounded"
                  />
                </div>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Change Password
                </button>
              </form>
            </div>
          )}
        </div>

        {/* API Connection Guide Section */}
        <div className="border rounded-lg">
          <button
            className="w-full text-left p-4 font-semibold bg-gray-100 hover:bg-gray-200 focus:outline-none"
            onClick={() => toggleSection("apiGuide")}
          >
            API Connection Guide {activeSection === "apiGuide" ? "▲" : "▼"}
          </button>
          {activeSection === "apiGuide" && (
            <div className="p-4">
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="text-lg font-semibold mb-2">Link API</h3>
                <pre className="bg-white p-2 rounded">
                  POST: https://bank-service.martool.online/v1/banks/history-api
                </pre>

                <h3 className="text-lg font-semibold mt-4 mb-2">
                  Body raw JSON
                </h3>
                <pre className="bg-white p-2 rounded">
                  {`{
    "bankId":3,
    "apiKey":"YOUR_API_KEY_HERE",
    "order":"desc",
    "offset":0,
    "limit":10
}`}
                </pre>

                <h3 className="text-lg font-semibold mt-4 mb-2">Response</h3>
                <pre className="bg-white p-2 rounded">
                  {`{
    "totalRecord": 3,
    "transactions": [
        {
            "id": 385,
            "transactionId": "MB-00001357730249404398",
            "transactionDate": "2024-08-31T01:29:00.000Z",
            "amount": 20000000,
            "direction": "OUT",
            "remarks": "NGUYEN VĂN A",
            "description": "CUSTOMER NGUYEN VĂN A chuyen tien - Ma gia o dich/ Trace 237874",
            "createdAt": "2024-08-31T15:08:33.140Z",
            "updatedAt": "2024-08-31T15:08:33.140Z",
            "bankAccountId": 3
        }
    ],
    "offset": 0,
    "limit": 10
}`}
                </pre>
              </div>
            </div>
          )}
        </div>

        {/* API Keys Section */}
        <div className="border rounded-lg">
          <button
            className="w-full text-left p-4 font-semibold bg-gray-100 hover:bg-gray-200 focus:outline-none"
            onClick={() => toggleSection("apiKeys")}
          >
            API Keys {activeSection === "apiKeys" ? "▲" : "▼"}
          </button>
          {activeSection === "apiKeys" && (
            <div className="p-4">
              <button
                onClick={handleCreateApiKey}
                className="bg-green-500 text-white px-4 py-2 rounded mb-4"
              >
                Create New API Key
              </button>
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border p-2 text-center">Key</th>
                    <th className="border p-2 text-center">Status</th>
                    <th className="border p-2 text-center">Created At</th>
                    <th className="border p-2 text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {apiKeys.length === 0 ? (
                    <tr>
                      <td className="border p-2 text-center" colSpan={4}>
                        No API keys available
                      </td>
                    </tr>
                  ) : (
                    apiKeys.map((key) => (
                      <tr key={key.id}>
                        <td className="border p-2 text-center">{key.key}</td>
                        <td className="border p-2 text-center">
                          {key.isRevoked ? "Revoked" : "Active"}
                        </td>
                        <td className="border p-2 text-center">
                          {new Date(key.createdAt).toLocaleString()}
                        </td>
                        <td className="border p-2 text-center">
                          <button
                            onClick={() =>
                              handleToggleRevoke(key.id, key.isRevoked)
                            }
                            className={`px-2 py-1 rounded ${
                              key.isRevoked ? "bg-green-500" : "bg-red-500"
                            } text-white`}
                          >
                            {key.isRevoked ? "Activate" : "Revoke"}
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {error && <p className="text-red-500 mt-4">{error}</p>}
      {success && <p className="text-green-500 mt-4">{success}</p>}
    </div>
  );
};

export default ProfilePage;
